.zoom-motion(@className, @keyframeName, @duration: @animation-duration-base) {
  .make-motion(@className, @keyframeName, @duration);
  .@{className}-enter,
  .@{className}-appear {
    transform: scale(0); // need this by yiminghe
    animation-timing-function: @ease-out-circ;
  }
  .@{className}-leave {
    animation-timing-function: @ease-in-out-circ;
  }
}

// For Modal, Select choosen item
.zoom-motion(zoom, antZoom);
// For Popover, Popconfirm, Dropdown
.zoom-motion(zoom-big, antZoomBig);
// For Tooltip
.zoom-motion(zoom-big-fast, antZoomBig, @animation-duration-fast);

.zoom-motion(zoom-up, antZoomUp);
.zoom-motion(zoom-down, antZoomDown);
.zoom-motion(zoom-left, antZoomLeft);
.zoom-motion(zoom-right, antZoomRight);

@keyframes antZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
  }
}

@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes antZoomUpIn {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
}

@keyframes antZoomUpOut {
  0% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(0.8);
  }
}

@keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
}

@keyframes antZoomLeftOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
}

@keyframes antZoomRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
}

@keyframes antZoomRightOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
}

@keyframes antZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes antZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0.8);
  }
}
