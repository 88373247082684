@import '../../style/themes/default';
@import '../../style/mixins/index';

@dropdown-prefix-cls: ~'@{ant-prefix}-dropdown';

.@{dropdown-prefix-cls} {
  .reset-component;
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: @zindex-dropdown;
  display: block;

  &:before {
    position: absolute;
    top: -7px;
    left: -7px;
    right: 0;
    bottom: -7px;
    content: ' ';
    opacity: 0.0001;
  }

  &-wrap {
    position: relative;

    .@{ant-prefix}-btn > .@{iconfont-css-prefix}-down {
      .iconfont-size-under-12px(10px);
    }

    .@{iconfont-css-prefix}-down:before {
      transition: transform 0.2s;
    }
  }

  &-wrap-open {
    .@{iconfont-css-prefix}-down:before {
      transform: rotate(180deg);
    }
  }

  &-hidden,
  &-menu-hidden {
    display: none;
  }

  &-menu {
    outline: none;
    position: relative;
    list-style-type: none;
    padding: 4px 0;
    margin: 0;
    text-align: left;
    background-color: @component-background;
    border-radius: @border-radius-base;
    box-shadow: @box-shadow-base;
    background-clip: padding-box;
    -webkit-transform: translate3d(0, 0, 0);

    &-item-group-title {
      color: @text-color-secondary;
      padding: 5px @control-padding-horizontal;
      transition: all 0.3s;
    }

    &-submenu-popup {
      position: absolute;
      z-index: @zindex-dropdown;

      > .@{dropdown-prefix-cls}-menu {
        transform-origin: 0 0;
      }
    }

    &-item,
    &-submenu-title {
      padding: 5px @control-padding-horizontal;
      margin: 0;
      clear: both;
      font-size: @font-size-base;
      font-weight: normal;
      color: @text-color;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      line-height: 22px;

      > .anticon:first-child {
        min-width: 12px;
        margin-right: 8px;
      }

      > a {
        color: @text-color;
        display: block;
        padding: 5px @control-padding-horizontal;
        margin: -5px -@control-padding-horizontal;
        transition: all 0.3s;
        &:focus {
          text-decoration: none;
        }
      }

      &-selected,
      &-selected > a {
        color: @primary-color;
        background-color: @item-active-bg;
      }

      &:hover {
        background-color: @item-hover-bg;
      }

      &-disabled {
        color: @disabled-color;
        cursor: not-allowed;

        &:hover {
          color: @disabled-color;
          background-color: @component-background;
          cursor: not-allowed;
        }
      }

      &-divider {
        height: 1px;
        overflow: hidden;
        background-color: @border-color-split;
        line-height: 0;
        margin: 4px 0;
      }
      .@{dropdown-prefix-cls}-menu-submenu-arrow {
        position: absolute;
        right: @padding-xs;
        &-icon {
          font-style: normal;
          color: @text-color-secondary;
          .iconfont-size-under-12px(10px);
        }
      }
    }

    &-submenu-title {
      padding-right: 26px;
    }

    &-submenu-vertical {
      position: relative;
    }

    &-submenu-vertical > & {
      top: 0;
      left: 100%;
      position: absolute;
      min-width: 100%;
      margin-left: 4px;
      transform-origin: 0 0;
    }

    &-submenu&-submenu-disabled .@{dropdown-prefix-cls}-menu-submenu-title {
      &,
      .@{dropdown-prefix-cls}-menu-submenu-arrow-icon {
        color: @disabled-color;
      }
    }
  }

  &.slide-down-enter.slide-down-enter-active&-placement-bottomLeft,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomLeft,
  &.slide-down-enter.slide-down-enter-active&-placement-bottomCenter,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomCenter,
  &.slide-down-enter.slide-down-enter-active&-placement-bottomRight,
  &.slide-down-appear.slide-down-appear-active&-placement-bottomRight {
    animation-name: antSlideUpIn;
  }

  &.slide-up-enter.slide-up-enter-active&-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active&-placement-topCenter,
  &.slide-up-appear.slide-up-appear-active&-placement-topCenter,
  &.slide-up-enter.slide-up-enter-active&-placement-topRight,
  &.slide-up-appear.slide-up-appear-active&-placement-topRight {
    animation-name: antSlideDownIn;
  }

  &.slide-down-leave.slide-down-leave-active&-placement-bottomLeft,
  &.slide-down-leave.slide-down-leave-active&-placement-bottomCenter,
  &.slide-down-leave.slide-down-leave-active&-placement-bottomRight {
    animation-name: antSlideUpOut;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-topLeft,
  &.slide-up-leave.slide-up-leave-active&-placement-topCenter,
  &.slide-up-leave.slide-up-leave-active&-placement-topRight {
    animation-name: antSlideDownOut;
  }
}

.@{dropdown-prefix-cls}-trigger,
.@{dropdown-prefix-cls}-link {
  > .@{iconfont-css-prefix}.@{iconfont-css-prefix}-down {
    .iconfont-size-under-12px(10px);
  }
}

.@{dropdown-prefix-cls}-button {
  white-space: nowrap;

  &.@{ant-prefix}-btn-group > .@{ant-prefix}-btn:last-child:not(:first-child) {
    padding-left: @padding-xs;
    padding-right: @padding-xs;
  }
  .@{iconfont-css-prefix}.@{iconfont-css-prefix}-down {
    .iconfont-size-under-12px(10px);
  }
}

// https://github.com/ant-design/ant-design/issues/4903
.@{dropdown-prefix-cls}-menu-dark {
  &,
  .@{dropdown-prefix-cls}-menu {
    background: @menu-dark-bg;
  }
  .@{dropdown-prefix-cls}-menu-item,
  .@{dropdown-prefix-cls}-menu-submenu-title,
  .@{dropdown-prefix-cls}-menu-item > a {
    color: @text-color-secondary-dark;
    .@{dropdown-prefix-cls}-menu-submenu-arrow:after {
      color: @text-color-secondary-dark;
    }
    &:hover {
      color: @text-color-inverse;
      background: transparent;
    }
  }
  .@{dropdown-prefix-cls}-menu-item-selected {
    &,
    &:hover,
    > a {
      background: @primary-color;
      color: @text-color-inverse;
    }
  }
}
