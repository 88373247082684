@import '../themes/default';
@import '../mixins/iconfont';

.@{iconfont-css-prefix} {
  .iconfont-mixin();
}

.@{iconfont-css-prefix}-spin:before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.@{iconfont-css-prefix}-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
